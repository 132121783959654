:host {
  position: relative;
  box-sizing: border-box;

  & *,
  & *:before,
  & *:after {
    box-sizing: inherit;
  }
}

/**
 * @prop --border-radius: The skeleton's border radius.
 * @prop --color: The color of the skeleton.
 * @prop --sheen-color: The sheen color when the skeleton is in its loading state.
 */
:host {
  --border-radius: var(--sc-border-radius-pill);
  --color: var(--sc-skeleton-color, var(--sc-color-gray-300));
  --sheen-color: var(--sc-skeleton-sheen-color, var(--sc-color-gray-400));

  display: block;
  position: relative;
}

.skeleton {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 1rem;
}

.skeleton__indicator {
  flex: 1 1 auto;
  background: var(--color);
  border-radius: var(--border-radius);
}

.skeleton--sheen .skeleton__indicator {
  background: linear-gradient(270deg, var(--sheen-color), var(--color), var(--color), var(--sheen-color));
  background-size: 400% 100%;
  background-size: 400% 100%;
  animation: sheen 3s ease-in-out infinite;
}

.skeleton--pulse .skeleton__indicator {
  animation: pulse 2s ease-in-out 0.5s infinite;
}

@keyframes sheen {
  0% {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
