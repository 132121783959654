:host {
  display: block;
  position: relative;
}

.dashboard-module {
  display: grid;
  gap: var(--sc-dashboard-module-spacing, 1em);
}

.heading {
  font-family: var(--sc-font-sans);
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: center;
  justify-content: space-between;

  &__text {
    display: grid;
    flex: 1;
    gap: calc(var(--sc-dashboard-module-spacing, 1em) / 2);
  }

  @media screen and (min-width: 720px) {
    gap: 2em;
  }
}

.heading__title {
  font-size: var(--sc-dashbaord-module-heading-size, var(--sc-font-size-x-large));
  font-weight: var(--sc-dashbaord-module-heading-weight, var(--sc-font-weight-bold));
  line-height: var(--sc-dashbaord-module-heading-line-height, var(--sc-line-height-dense));
  white-space: nowrap;
}

.heading__description {
  font-size: var(--sc-font-size-normal);
  line-height: var(--sc-line-height-dense);
  opacity: 0.85;
}
