/**
 * @prop --track-color: The color of the spinner's track.
 * @prop --indicator-color: The color of the spinner's indicator.
 * @prop --stroke-width: The width of the indicator.
 */
:host {
  --track-color: #0d131e20;
  --indicator-color: var(--sc-color-primary-500);
  --stroke-width: 2px;
  --spinner-size: 1em;

  display: inline-block;
}

.spinner {
  display: inline-block;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-radius: 50%;
  border: solid var(--stroke-width) var(--track-color);
  border-top-color: var(--indicator-color);
  border-right-color: var(--indicator-color);
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
